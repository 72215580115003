<template>
  <Modal :visible="visible"
         :style="computedWindowSize"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Order cancellation</div>
    </template>
    <Spinner v-if="dataIsSending" :isSmall="activeOrderedRequestPositionsData?.length ? false : true"></Spinner>
    <template v-else>
      <div v-show="activeOrderedRequestPositionsData?.length" class="p-formgrid p-grid p-mb-2">
        <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 table-wrapper">
          <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
            <thead class="p-datatable-thead">
            <tr>
              <!--              <th style="width: 15%">Supplier</th>-->
              <!--              <th style="width: 20%">Code</th>-->
              <!--              <th style="width: 30%">Name</th>-->
              <!--              <th style="width: 10%">Qty</th>-->
              <th>Supplier</th>
              <th>Code</th>
              <th>Name</th>
              <!--              <th>Qty</th>-->
              <th>Qty<span class="warning-color">*</span></th>
              <th>Type<span class="warning-color">*</span></th>
              <th>Reason<span class="warning-color">*</span></th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody class="p-datatable-tbody">
            <template v-for="positionData of activeOrderedRequestPositionsData" :key="positionData.id">
              <tr>
                <td>
                  <div>{{ positionData.supplier?.company_name }}</div>
                </td>
                <td>
                  <div v-if="positionData.code">{{ positionData.code }}</div>
                  <i v-else class="ti-minus"></i>
                </td>
                <td>
                  <div>{{ positionData.name }}</div>
                </td>
                <!--                <td>-->
                <!--                  <div>{{ formatDecimal(positionData.qty) }}</div>-->
                <!--                </td>-->
                <td>
                  <div>{{ formatDecimal(positionData.qty) }}</div>
                  <!--                  <InputNumber id="reduce_position_qty"-->
                  <!--                               :locale="computedNumberInputLocale"-->
                  <!--                               style="width: 90px"-->
                  <!--                               :class="{'p-invalid' : submitted && !positionData.qty}"-->
                  <!--                               v-model="positionData.qty"-->
                  <!--                               disabled-->
                  <!--                               mode="decimal"-->
                  <!--                               :maxFractionDigits="2"-->
                  <!--                               :min="0"-->
                  <!--                               autocomplete="off"/>-->
                </td>
                <td>
                  <CustomDropdown class="dropdown__select dropdown__select--one-button-width"
                                  style="min-width: 150px"
                                  :disabled="!positionData?.qty"
                                  :class="{'p-invalid' : submitted && !positionData.selectedType}"
                                  v-model="positionData.selectedType"
                                  :options="ReducedPositionTypes">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <div>
                          <span>{{ slotProps.value.label[$i18n.locale] }}</span>
                        </div>
                      </div>
                      <span v-else>Select type</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.label[$i18n.locale] }}</span>
                      </div>
                    </template>
                  </CustomDropdown>
                </td>
                <td>
                  <CustomDropdown class="dropdown__select dropdown__select--one-button-width"
                                  style="min-width: 150px"
                                  :disabled="!positionData?.qty"
                                  :class="{'p-invalid' : submitted && !positionData.selectedReason}"
                                  v-model="positionData.selectedReason"
                                  :options="ReducedPositionReasons">
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <div>
                          <span>{{ slotProps.value.label[$i18n.locale] }}</span>
                        </div>
                      </div>
                      <span v-else>Select reason</span>
                    </template>
                    <template #option="slotProps">
                      <div>
                        <span>{{ slotProps.option.label[$i18n.locale] }}</span>
                      </div>
                    </template>
                  </CustomDropdown>
                </td>
                <td>
                  <InputText id="return_position_comment"
                             v-model="positionData.comment"
                             :class="{'p-invalid' : submitted && (positionData.selectedReason?.id === 9 && !positionData.comment?.length)}"
                             autocomplete="off"></InputText>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>

      <div class="p-formgrid p-grid p-pt-4">
        <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
          <!--        <div class="p-float-label p-input-icon-right" >-->
          <!--          <i class="ti-comment-alt"></i>-->
          <!--          <Textarea :class="{'p-invalid' : submitted && !reason}" id="order_cancellation_reason" v-model.trim="reason" rows="2" :autoResize="true" autocomplete="off"/>-->
          <!--          <label for="order_cancellation_reason">Cancellation reason<span class="warning-color">*</span></label>-->
          <!--        </div>-->
          <!--        <small class="p-invalid" v-if="submitted && !reason">{{ $t('Required field') }}</small>-->
          <LimitedCharsTextarea :submitted="submitted"
                                :rows="2"
                                v-model="reason"
                                :maxChars="cancellationReasonMaxChars"
                                :required="true"
                                :id="'order-cancellation-reason'"
                                :label="'Cancellation reason'"/>
        </div>
      </div>
    </template>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="cancelOrder" />
    </template>
  </Modal>
</template>

<script>
// import httpMixins from "@/mixins/httpMixins";
import httpClient from "@/services/http.services";
import ReducedPositionReasons from '@/translations/ReducedPositionReasons'
import ReducedPositionTypes from "@/translations/ReducedPositionTypes";
import formatMixins from "@/mixins/formatMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ showErrorsMixins, formatMixins ],
  name: 'OrderCancellationModal',
  emits: ['close', 'update-item'],
  props: {
  //   data: String,
  //   header: null,
  //   footer: null,
    order: Object,
    visible: Boolean,
    activeOrderedRequestPositions: [Array, null]
  //   style: Object,
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.reason = null
      }
    },
    // order(value) {
    //   if (!value) return false
    //   this.reason = value.cancellation_reason ?? null
    // },
    // activeOrderedRequestPositions(value) {
    //   if (value?.length) {
    //     this.activeOrderedRequestPositionsData = value.map(p => {
    //       return {
    //         id: p.id,
    //         supplier: p.supplier,
    //         code: p.code,
    //         name: p.name,
    //         qty: +p.qty,
    //         selectedType: ReducedPositionTypes[0]
    //       }
    //     })
    //   } else {
    //     return []
    //   }
    // }
    order: {
      handler(value) {
        if (!value) return false
        this.reason = value.cancellation_reason ?? null

        const activeOrderedRequestPositions = value.positions?.filter(pos => pos.status && pos.type === 2 && pos.state >= 4)
        if (activeOrderedRequestPositions?.length) {
          this.activeOrderedRequestPositionsData = activeOrderedRequestPositions.map(p => {
            return {
              id: p.id,
              supplier: p.supplier,
              code: p.code,
              name: p.name,
              qty: +p.qty,
              selectedType: ReducedPositionTypes[0]
            }
          })
        } else {
          this.activeOrderedRequestPositionsData = []
        }
      },
      deep: true
    }
  },
  data() {
    return {
      dataIsSending: false,
      disableSaveButton: false,
      cancellationReasonMaxChars: 250,
      activeOrderedRequestPositionsData: [],
      submitted: false,
      reason: null,
      ReducedPositionReasons,
      ReducedPositionTypes,
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async cancelOrder() {
      this.submitted = true
      if (!this.reason ||
          (this.reason && this.reason.length > this.cancellationReasonMaxChars)) {
        return false
      }

      let wrongInputs = false

      // if (this.computedActiveOrderedRequestPositionsData.length) {
      //   this.computedActiveOrderedRequestPositionsData.forEach(position => {
      //     if (!position.selectedReason || !position.selectedType) {
      //       wrongInputs = true
      //     }
      //   })
      // }

      if (this.activeOrderedRequestPositionsData?.length) {
        this.activeOrderedRequestPositionsData.forEach(position => {
          if (!position.selectedReason ||
              !position.selectedType ||
              (position.selectedReason && position.selectedReason.id === 9 && !position.comment?.length)) {
            wrongInputs = true
          }
        })
      }

      if (wrongInputs) {
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const obj = {
        id: this.order.id,
        reason: this.reason,
        reducedPositions: this.activeOrderedRequestPositionsData?.length ? this.activeOrderedRequestPositionsData?.map(p => {
          return {
            id: p.id,
            returnTypeId: p.selectedType?.id ?? null,
            returnReasonId: p.selectedReason?.id ?? null,
            returnComment: p.comment ?? null
          }
        }) : null
      }

      // console.log(obj.reducedPositions)

      try {
        const { status, data } = await httpClient.post('order/cancel', obj)
        if (status === 200 && data?.success) {
          this.close()
          this.$emit('update-item', this.order.id, true)
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
        this.disableSaveButton = false
      }
    },
  },
  computed: {
    // computedWindowSize() {
    //   if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '85%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '25px'}
    //   } else {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '50px'}
    //   }
    // },
    computedWindowSize() {
      if (this.activeOrderedRequestPositionsData.length) {
        if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
          return {position: 'absolute', top: 0, width: '90%', marginTop: '10px'}
        } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
          return {position: 'absolute', top: 0, width: '82%', marginTop: '15px'}
        } else {
          return {position: 'absolute', top: 0, width: '87%', maxWidth: '1100px'}
        }
      } else {
          if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
            return {position: 'relative', top: '-10vh', width: '85%', marginTop: '10px'}
          } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
            return {position: 'relative', top: '-10vh', width: '450px', marginTop: '25px'}
          } else {
            return {position: 'relative', top: '-10vh', width: '450px', marginTop: '50px'}
          }
      }
    },
    // computedActiveOrderedRequestPositionsData() {
    //   if (this.activeOrderedRequestPositions?.length) {
    //     return this.activeOrderedRequestPositions.map(p => {
    //       return {
    //         id: p.id,
    //         supplier: p.supplier,
    //         code: p.code,
    //         name: p.name,
    //         qty: +p.qty,
    //         selectedType: ReducedPositionTypes[0]
    //       }
    //     })
    //   } else {
    //     return []
    //   }
    // }
    // activeOrderedRequestPositions() {
    //   return this.order?.positions?.filter(pos => pos.status && pos.type === 2 && pos.state >= 4)?.map(p => {
    //     return {
    //       // ...p,
    //       id: p.id,
    //       supplier: p.supplier,
    //       code: p.code,
    //       name: p.name,
    //       qty: +p.qty,
    //       selectedType: ReducedPositionTypes[0]
    //     }
    //   })
    // },
    // thereIsActiveOrderedRequestPositions() {
    //   return !!this.order?.positions?.find(pos => pos.status && pos.type === 2 && pos.state >= 4)
    // }
  }
}
</script>
<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
  overflow-y: auto;
  .positions-table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>