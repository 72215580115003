<template>
  <Modal :visible="visible"
         :style="computedWindowSize"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ $t('Confirm') }}</div>
    </template>
<!--    <Spinner v-if="showSpinner" :isSmall="true" :spinnerStyle="'height:60px'"/>-->
    <Spinner v-if="showSpinner" :isSmall="true"/>
    <div v-else class="p-formgrid p-grid">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-d-flex p-ai-center">
          <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
<!--          <span>{{ $t('Are you sure you want to delete selected item?') }}</span>-->
          <span>{{ 'Спросить у клиента про секретку!' }}</span>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-pt-4">
        <LimitedCharsTextarea :submitted="submitted"
                              :rows="4"
                              v-model="staffComment"
                              :maxChars="internalCommentMaxChars"
                              :id="'order-modal-staff-comment'"
                              :label="'Internal comment'"/>
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Close')" :disabled="showSpinner" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="showSpinner" icon="pi pi-check" class="p-button-text" @click="changeState" />
    </template>
  </Modal>
</template>

<script>
// import httpMixins from "@/mixins/httpMixins";
// import formatMixins from "@/mixins/formatMixins";

export default {
  // mixins: [ formatMixins ],
  name: 'ChangeOrderToWaitingModal',
  emits: ['close', 'update-item', 'change-state'],
  props: {
  //   data: String,
  //   header: null,
  //   footer: null,
    order: Object,
    visible: Boolean,
    showSpinner: {
      type: Boolean,
      default: false
    },
    orderData: {
      type: [Object,null],
      default: null
    },
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.submitted = false
        // this.reason = null
      }
    },
    orderData: {
      handler(value) {
        if (!value) return false
        this.staffComment = value.staffComment
        // this.reason = value.cancellation_reason ?? null
      },
      deep: true
    }
  },
  data() {
    return {
      internalCommentMaxChars: 1000,
      submitted: false,
      staffComment: null,
    }
  },
  methods: {
    changeState() {
      const obj = {
        id: this.orderData.id,
        staffComment: this.staffComment
      }
      this.$emit('change-state', obj)
    },
    close() {
      this.$emit('close')
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '85%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '550px', marginTop: '25px'}
      } else {
        return {position: 'relative', top: '-10vh', width: '600px', marginTop: '50px'}
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>