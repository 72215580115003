export default {
    offerEstimated: {
        en: {
            subject: 'Price offer #',
            greeting: 'Hello,',
            text: 'A price offer has been made for you.',
            linkText: 'You can read the price offer and book a suitable time by clicking the link: ',
            pinText: 'PIN: '
        },
        et: {
            subject: 'Hinnapakkumine #',
            greeting: 'Tere,',
            text: 'Teie jaoks on koostatud hinnapakkumine.',
            linkText: 'Hinnapakkumisega saate tutvuda ja broneerida sobiv aeg vajudes linki: ',
            pinText: 'PIN: '
        },
        ru: {
            subject: 'Ценовое предложение #',
            greeting: 'Здравствуйте,',
            text: 'Для Вас составлено ценовое предложение.',
            linkText: 'Вы можете ознакомится с ним, а также записаться на удобное для Вас время онлайн, перейдя по ссылке: ',
            pinText: 'ПИН: '
        }
    },
    salesInvoice: {
        en: {
            subject: 'Sales Invoice #',
            text: 'Hello,<br>An invoice has been prepared for you, which is available in PDF format from the attachment.'
        },
        et: {
            subject: 'Müügiarve #',
            text: 'Tere,<br>Teile on koostatud arve, mis on PDF-kujul kättesaadav kirja manusest.',
        },
        ru: {
            subject: 'Счет #',
            text: 'Здравствуйте,<br>Для вас был создан счет-фактура, который доступен в формате PDF из приложения.',
        }
    }

    // en: {
    //     offer: {
    //         subject: 'Price offer #',
    //         text: 'Hello,<br>A price offer has been made for you.',
    //         linkText: 'You can read the price offer and book a suitable time by clicking the link: ',
    //         pinText: 'PIN: '
    //     },
    //     salesInvoice: {
    //         subject: 'Sales Invoice #',
    //         text: 'Hello,<br>An invoice has been prepared for you, which is available in PDF format from the attachment.'
    //     }
    // },
    // et: {
    //     offer: {
    //         subject: 'Hinnapakkumine #',
    //         text: 'Tere,<br>Teie jaoks on koostatud hinnapakkumine.',
    //         linkText: 'Hinnapakkumisega saate tutvuda ja broneerida sobiv aeg vajudes linki: ',
    //         pinText: 'PIN: '
    //     },
    //     salesInvoice: {
    //         subject: 'Müügiarve #',
    //         text: 'Tere,<br>Teile on koostatud arve, mis on PDF-kujul kättesaadav kirja manusest.',
    //     },
    // },
    // ru: {
    //     offer: {
    //         subject: 'Ценовое предложение #',
    //         text: 'Здравствуйте,<br>Для Вас составлено ценовое предложение.',
    //         linkText: 'Вы можете ознакомится с ним, а также записаться на удобное для Вас время онлайн, перейдя по ссылке: ',
    //         pinText: 'ПИН: '
    //     },
    //     salesInvoice: {
    //         subject: 'Счет #',
    //         text: 'Здравствуйте,<br>Для вас был создан счет-фактура, который доступен в формате PDF из приложения.',
    //     },
    // }
}