export default [
    {
        type: 1,
        label: {
            en: 'Cash',
            et: 'Sularaha',
            ru: 'Наличные деньги'
        },
    },
    {
        type: 3,
        label: {
            en: 'Bank card',
            et: 'Pangakaart',
            ru: 'Банковская карта'
        },
    },
    {
        type: 5,
        label: {
            en: 'Bank transfer',
            et: 'Pangaülekanne',
            ru: 'Банковский перевод'
        },
    },
]