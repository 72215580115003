<template>
  <Modal :visible="visible"
         :style="computedWindowSize"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Edit archiving date</div>
    </template>
    <Spinner v-if="dataIsSending" :isSmall="true"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-float-label">
          <Calendar @show="toogleDropdownOverlayVisibility(true)"
                    @hide="toogleDropdownOverlayVisibility(false)"
                    :class="{'p-invalid' : submitted && !selectedDateAndTime}"
                    :showButtonBar="true"
                    :showTime="true"
                    :stepMinute="10"
                    :dateFormat="settings.dateFormat"
                    id="order_start"
                    v-model="selectedDateAndTime"
                    :showIcon="true"
                    :selectOtherMonths="true"
                    :maxDate="new Date()"
                    autocomplete="off"/>
          <!--          <label for="order_start" :class="{'warning-color' : !startDateIsValid || (submitted && !itemData.start)}">Order start<span class="warning-color">*</span></label>-->
          <label for="order_start">Date of archiving<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedDateAndTime">{{ $t('Required field') }}</small>
<!--        <small class="p-invalid" v-if="itemData.start && !startDateIsValid">{{ $t('Invalid date format') }}</small>-->
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="changeArchivingDate" />
    </template>
  </Modal>
</template>

<script>
// import httpMixins from "@/mixins/httpMixins";
import httpClient from "@/services/http.services";
import settings from "@/settings";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ showErrorsMixins, overlayVisibilityMixins ],
  name: 'ArchivedAtDateModal',
  emits: ['close', 'update-item'],
  props: {
    order: Object,
    visible: Boolean,
    archivedAt: [Number, null],
    orderId: [Number, null]
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.selectedDateAndTime = null
      } else {
        if (this.archivedAt) {
          this.selectedDateAndTime = new Date(this.archivedAt * 1000)
        }
      }
    },
  },
  data() {
    return {
      dataIsSending: false,
      disableSaveButton: false,
      archivedAtDateIsValid: false,
      submitted: false,
      selectedDateAndTime: null,
      settings
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async changeArchivingDate() {
      this.submitted = true
      if (!this.selectedDateAndTime || !this.orderId) {
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const obj = {
        orderId: this.orderId,
        timestamp: +new Date(this.selectedDateAndTime / 1000)
      }

      try {
        const { status, data } = await httpClient.post('order/change-archiving-date', obj)
        if (status === 200 && data?.success) {
          this.close()
          this.$emit('update-item')
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsSending = false
        this.disableSaveButton = false
      }
    },
  },
  computed: {
    computedWindowSize() {
    //   if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '85%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '25px'}
    //   } else {
    //     return {position: 'relative', top: '-10vh', width: '450px', marginTop: '50px'}
    //   }
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '85%'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '450px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '450px', maxWidth: '450px'}
      }

    }
  }
}
</script>