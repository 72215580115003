<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
<!--      <div v-if="!item?.id" class="p-dialog-title">Employee payments</div>-->
<!--      <div v-else class="p-dialog-title">Employee payments</div>-->
      <div class="p-dialog-title">Employee payments</div>
    </template>
    <Spinner v-if="dataIsLoading" :isSmall="true" :spinnerStyle="'height:60px'"/>
    <div v-else class="p-formgrid p-grid">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 table-wrapper">
<!--      <div class="table-wrapper">-->
        <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
          <thead class="p-datatable-thead">
            <tr>
              <th>Master</th>
              <th style="width: 22%; min-width: 200px">Service name</th>
              <th>Qty</th>
              <th>Sell price</th>
              <th>Time</th>
              <th>Total sum</th>
              <th>Total time</th>
              <th>Date</th>
              <th>Percent</th>
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="p-datatable-tbody" v-if="servicePositionsData?.length">
            <tr v-for="(servicePosition, index) of servicePositionsData" :key="index">
              <td :class="{'new-item':servicePosition.isNew}">
                <span v-if="servicePosition.master">{{ servicePosition.master.first_name }} {{ servicePosition.master.last_name }}</span>
              </td>
              <td :class="{'new-item':servicePosition.isNew}">
                <InputText :class="{'p-invalid' : submitted && !servicePosition.description}" @input="servicePosition.updated = true" v-model.trim="servicePosition.description" autocomplete="off"/>
              </td>
              <td :class="{'new-item':servicePosition.isNew}">{{ servicePosition.qty }}</td>
              <td :class="{'new-item':servicePosition.isNew}">
                <span v-if="servicePosition.service_sell_price">{{ formatCurrency(servicePosition.service_sell_price) }}</span>
              </td>
              <td :class="{'new-item':servicePosition.isNew}">
                <span v-if="servicePosition.time != 0">{{ servicePosition.time }} {{ $t('h') }}.</span>
              </td>
              <td :class="{'new-item':servicePosition.isNew}">
                <span v-if="servicePosition.qty && servicePosition.service_sell_price">{{ formatCurrency(servicePosition.service_sell_price * servicePosition.qty) }}</span>
              </td>
              <td :class="{'new-item':servicePosition.isNew}">
  <!--              <span v-if="servicePosition.time != 0">{{ servicePosition.time * servicePosition.qty }} {{ $t('h') }}.</span>-->
                <span v-if="servicePosition.time != 0">{{ servicePosition.total_time }} {{ $t('h') }}.</span>
              </td>
              <td :class="{'new-item':servicePosition.isNew}">
                <Calendar style="width: 175px"
                          @date-select="changePaymentDateIsValid(servicePosition)"
                          @show="toogleDropdownOverlayVisibility(true)"
                          @hide="toogleDropdownOverlayVisibility(false)"
                          :showButtonBar="true"
                          :class="{'p-invalid' : (servicePosition.selectedDate && !servicePosition.dateIsValid) || (submitted && !servicePosition.selectedDate)}"
                          v-model="servicePosition.selectedDate"
                          :dateFormat="settings.dateFormat"
                          :showIcon="true"
                          :selectOtherMonths="true"
                          :showTime="true"
                          :stepMinute="10"
                          :maxDate="new Date()"
                          autocomplete="off"/>
                <small class="p-invalid" v-if="servicePosition.selectedDate && !servicePosition.dateIsValid">{{ $t('Invalid date format') }}</small>
              </td>
              <td :class="{'new-item':servicePosition.isNew}">
  <!--              <InputNumber @input="changePercent(payment, $event.value)" v-model="payment.percent" style="width: 90px" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"/>-->
                <InputNumber @input="changePercent(servicePosition, $event.value)"
                             :locale="computedNumberInputLocale"
                             v-model="servicePosition.percent"
                             style="width: 90px"
                             mode="decimal"
                             :min="0"
                             :max="100"
                             :minFractionDigits="2"
                             :maxFractionDigits="2"
                             autocomplete="off"/>
              </td>

              <td :class="{'new-item':servicePosition.isNew}">
                <InputNumber @input="changeValue(servicePosition, $event.value)"
                             :locale="computedNumberInputLocale"
                             v-model="servicePosition.sum"
                             style="width: 90px"
                             mode="decimal"
                             :min="servicePosition.qty < 0 ? servicePosition.service_sell_price * servicePosition.qty : 0"
                             :max="servicePosition.qty > 0 ? servicePosition.service_sell_price * servicePosition.qty : 0"
                             :minFractionDigits="2"
                             :maxFractionDigits="2"
                             autocomplete="off"/>
              </td>
              <td :class="{'new-item':servicePosition.isNew}">
                <DeleteButton :disabled="servicePosition.isNew" @click="confirmDeletePayment(servicePosition)"/>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="no-data-block" colspan="9">{{ $t('No data available') }}</td>
            </tr>
  <!--          <div class="no-data-block"></div>-->
          </tbody>
        </table>
      </div>
    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="saveItems" />
    </template>
  </Modal>

  <ConfirmDeleteModal
      :modalDataIsLoading="modalDataIsLoading"
      :visible="confirmDeletePaymentModal"
      @close="closeConfirmDeletePaymentModal"
      @delete-item="deleteEmployeePayment">
  </ConfirmDeleteModal>
</template>

<script>
import httpClient from '@/services/http.services'
import settings from '@/settings'
// import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ formatMixins, overlayVisibilityMixins, showErrorsMixins ],
  emits: ['close', 'update-item'],
  name: 'EmployeePaymentsModal',
  props: {
    item: Object,
    visible: Boolean,
    orderMasters: Array,
    servicePositions: Array,
    employeePayments: Array,
    orderId: Number,
    salarySettings: Array,
  },
  data() {
    return {
      dataIsLoading: false,
      modalDataIsLoading: false,
      confirmDeletePaymentModal: false,
      paymentToDelete: {},
      settings,
      // employeePaymentsData: [],
      servicePositionsData: [],
      paymentsSettings: null,
      submitted: false
    }
  },
  watch: {
    servicePositions() {
      this.calculatePayments()
      // this.servicePositionsData = positions.map(service => {
      //   let defaultPercent = null
      //   let percentValue = null
      //   const masterSalarySettings = this.salarySettings.length ? this.salarySettings.filter(item => item.userId === service.master?.id)[0] : null
      //   if (masterSalarySettings) {
      //     const settings = JSON.parse(masterSalarySettings.salary_settings)
      //     const serviceData = settings?.services?.filter(item => item.id === service.service?.id)[0]
      //
      //     percentValue = serviceData?.percent ? +serviceData?.percent : null
      //     defaultPercent = settings?.default_percent ? +settings.default_percent : null
      //   }
      //   if (!service.master) return false
      //
      //   const payment = this.employeePayments.length ? this.employeePayments.filter(payment => payment.order_position_id === service.id)[0] : null
      //   if (payment)  {
      //     const selectedDate = payment.date ? new Date(payment.date * 1000) : new Date()
      //     return {
      //       isNew: false,
      //       id: payment.id,
      //       order_id: payment.order_id,
      //       // service_id: service.service?.id,
      //       order_position_id: payment.order_position_id,
      //       master: payment.master,
      //       name: payment.name,
      //       qty: +payment.qty,
      //       service_sell_price: +payment.service_sell_price,
      //       time: (+payment.time).toFixed(2),
      //       total_time: (+payment.total_time).toFixed(2),
      //       selectedDate: selectedDate,
      //       dateIsValid: this.checkDateIsValid(selectedDate),
      //       percent: +payment.percent,
      //       sum: +payment.sum
      //     }
      //   } else {
      //     return {
      //       isNew: true,
      //       id: null,
      //       order_id: this.orderData?.id,
      //       // service_id: service.service?.id,
      //       order_position_id: service.id,
      //       master: service.master,
      //       name: service.name,
      //       qty: +service.qty,
      //       service_sell_price: +service.sell_price,
      //       time: service.qty < 0 ? (-service.service?.time)?.toFixed(2) : (+service.service?.time)?.toFixed(2),
      //       total_time: (service.service?.time * service.qty)?.toFixed(2),
      //       selectedDate: new Date(),
      //       dateIsValid: true,
      //       percent: percentValue ? +percentValue : +defaultPercent,
      //       sum: +(service.sell_price * ((percentValue ? +percentValue : +defaultPercent) / 100)) * service.qty,
      //     }
      //   }
      // })
    },
    // orderMasters() {
    //   if (this.orderMasters && this.orderMasters.length) {
    //     this.paymentsSettings = this.orderMasters.map(master => {
    //       return {
    //         masterId: master.id,
    //         salary_settings: master.employee?.salary_settings
    //       }
    //     })
    //   }
    //
    //   console.log(this.paymentsSettings)
    // },
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.servicePositionsData = []
        // this.servicePositionsData = []
        // this.paymentsSettings = null
      } else {
        this.calculatePayments()
      }
    },
    '$store.state.secondLayerIsOpened'() {
      if (!this.$store.state.secondLayerIsOpened) {
        if (this.confirmDeletePaymentModal) {
          this.closeConfirmDeletePaymentModal()
        }
      }
    },
  },
  methods: {
    calculatePayments() {
      this.servicePositionsData = this.servicePositions.map(service => {
        let defaultPercent = null
        let percentValue = null
        const masterSalarySettings = this.salarySettings?.length ? this.salarySettings.find(item => item.userId === service.master?.id) : null
        if (masterSalarySettings?.salary_settings) {
          const settings = JSON.parse(masterSalarySettings.salary_settings)
          const serviceData = settings?.services?.find(item => item.id === service.service?.id)

          percentValue = serviceData?.percent ? +serviceData?.percent : null
          defaultPercent = settings?.default_percent ? +settings.default_percent : null
        }

        if (!service.master) return false

        const payment = this.employeePayments.length ? this.employeePayments.find(payment => payment.order_position_id === service.id) : null
        if (payment)  {
          const selectedDate = payment.date ? new Date(payment.date * 1000) : new Date()
          return {
            isNew: false,
            id: payment.id,
            order_id: payment.order_id,
            // service_id: service.service?.id,
            order_position_id: payment.order_position_id,
            master: payment.master,
            description: payment.description,
            qty: +payment.qty,
            service_sell_price: +payment.service_sell_price,
            time: this.formatDecimal(payment.time),
            total_time: this.formatDecimal(payment.total_time),
            selectedDate: selectedDate,
            dateIsValid: this.checkDateIsValid(selectedDate),
            percent: +payment.percent,
            sum: +payment.sum
          }
        } else {
          return {
            isNew: true,
            id: null,
            order_id: this.orderData?.id,
            // service_id: service.service?.id,
            order_position_id: service.id,
            master: service.master,
            description: service.name,
            qty: +service.qty,
            service_sell_price: +service.sell_price,
            time: service.qty < 0 ? this.formatDecimal(-service.service?.time) : this.formatDecimal(service.service?.time),
            total_time: this.formatDecimal(service.service?.time * service.qty),
            selectedDate: new Date(),
            dateIsValid: true,
            percent: percentValue ? +percentValue : +defaultPercent,
            sum: +(service.sell_price * ((percentValue ? +percentValue : +defaultPercent) / 100)) * service.qty,
          }
        }
      })
    },
    changePaymentDateIsValid(servicePosition) {
      if (!servicePosition) return false
      servicePosition.dateIsValid = this.checkDateIsValid(servicePosition.selectedDate)
      servicePosition.updated = true
    },
    checkDateIsValid(date) {
      if (!date) return false
      return !!(date instanceof Date && !isNaN(date))
    },
    confirmDeletePayment(payment) {
      this.paymentToDelete = payment
      this.confirmDeletePaymentModal = true
      this.$store.commit('toggleSecondLayer', true)
    },
    closeConfirmDeletePaymentModal() {
      this.paymentToDelete = {}
      this.confirmDeletePaymentModal = false
      this.$store.commit('toggleSecondLayer', false)
    },
    async deleteEmployeePayment() {
      this.modalDataIsLoading = true
      if (!this.paymentToDelete) return false
      const employeePayment = {
        id: this.paymentToDelete.id,
        order_position_id: this.paymentToDelete.order_position_id
      }
      try {
        const { status, data } = await httpClient.post(`employee-payment/delete`, employeePayment)
        if (status === 200 && data?.success) {
          this.$emit('update-item', null, true)
          this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
          this.closeConfirmDeletePaymentModal()
          // this.close()
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.modalDataIsLoading = false
      }
    },
    async saveItems() {
      this.dataIsLoading = true
      this.submitted = true
      let wrongInputs = false

      let newPayments = this.servicePositionsData.filter(payment => payment.isNew)
      let updatedPayments = this.servicePositionsData.filter(payment => !payment.isNew && payment.updated)

      if (newPayments?.length) {
        newPayments = newPayments.map(payment => {
          // if (payment.master?.id === null) return false
          if (!payment.description || !payment.selectedDate || !payment.dateIsValid) {
            wrongInputs = true
          }
          return {
            user_id: payment.master?.id,
            order_id: this.orderId,
            order_position_id: payment.order_position_id,
            service_sell_price: payment.service_sell_price,
            qty: payment.qty,
            time: payment.time,
            total_time: payment.total_time,
            percent: payment.percent,
            sum: payment.sum,
            date: payment.selectedDate ? +new Date(payment.selectedDate / 1000) : +new Date() / 1000,
            description: payment.description
          }
        })
      }

      if (updatedPayments?.length) {
        updatedPayments = updatedPayments.map(payment => {
          // if (payment.master?.id === null) return false
          if (!payment.description || !payment.selectedDate || !payment.dateIsValid) {
            wrongInputs = true
          }
          return {
            id: payment.id,
            user_id: payment.master?.id,
            order_id: this.orderId,
            order_position_id: payment.order_position_id,
            service_sell_price: payment.service_sell_price,
            qty: payment.qty,
            time: payment.time,
            total_time: payment.total_time,
            percent: payment.percent,
            sum: payment.sum,
            date: payment.selectedDate ? +new Date(payment.selectedDate / 1000) : +new Date() / 1000,
            description: payment.description
          }
        })
      }

      if (wrongInputs) {
        return false
      }

      const obj = {
        newPayments: newPayments && newPayments.length ? newPayments : null,
        updatedPayments: updatedPayments && updatedPayments.length ? updatedPayments : null,
        orderId: this.orderId
      }

      try {
        const { status, data } = await httpClient.post(`employee-payment/save-order-payments`, obj)
        if (status === 200 && data?.success) {
          this.$emit('update-item', null, true) //True === sent socket
          this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
          this.close()
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
        } else if (data?.order_cancelled) {
          this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Order already cancelled', life: this.settings.toastLife});
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.dataIsLoading = false
      }
    },
    changePercent(payment, value) {
      if (payment.id) {
        payment.updated = true
      }

      if (value > 0) {
        payment.percent = value > 100 ? 100 : value
      } else {
        payment.percent = 0
      }
      const itemSellPrice = +payment.service_sell_price
      if (itemSellPrice) {
          payment.sum = (itemSellPrice * (payment.percent / 100)) * payment.qty
      }
    },
    changeValue(payment, value) {
      if (payment.id) {
        payment.updated = true
      }
      const itemSellPrice = +payment.service_sell_price

      if (itemSellPrice) {
        if (payment.qty > 0) {
          payment.sum = value > itemSellPrice * payment.qty ? itemSellPrice * payment.qty : value
        } else {
          payment.sum = value < itemSellPrice * payment.qty ? itemSellPrice * payment.qty : value
        }
        const percentValue = (payment.sum * 100) / (itemSellPrice * payment.qty)
        this.changePercent(payment, percentValue)
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      // if (this.$store.state.mobileLayout) {
      //   return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      // } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
      //   return {position: 'absolute', top: 0, width: '90%', marginTop: '15px'}
      // } else {
      //   return {position: 'absolute', top: 0, width: '90%', maxWidth: '1300px'}
      // }
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '90%', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '90%', maxWidth: '1300px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
.table-wrapper {
  width: 100%;
  overflow-y: auto;
  .positions-table {
    width: 100%;
    border-collapse: collapse;
  }
}

.no-data-block {
  //width: 100%;
  //margin: 20px auto;
  padding: 30px 0;
  text-align: center;
  //background-color: red;
}

</style>